var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var WI,YI,$I,lJ,pJ,xJ;$CLJS.VI=new $CLJS.M(null,"get-month","get-month",-369374731);WI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.XI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);YI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.ZI=new $CLJS.M(null,"iso","iso",-1366207543);$I=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.aJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.bJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.cJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.dJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.eJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.fJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.gJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.hJ=new $CLJS.M(null,"us","us",746429226);$CLJS.iJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.jJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.kJ=new $CLJS.M(null,"now","now",-1650525531);lJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.mJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.nJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.oJ=new $CLJS.M(null,"get-week","get-week",-888059349);
pJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.qJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.rJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.sJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.tJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.uJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.vJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.wJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);xJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.yJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.zJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.AJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.BJ=new $CLJS.M(null,"target","target",253001721);$CLJS.GF($CLJS.gJ,$CLJS.H([$CLJS.st,$CLJS.kD,$CLJS.Ls,$CLJS.nE]));$CLJS.VE.m(null,$CLJS.dJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.WE(a);return $CLJS.vd(a)?(a=$CLJS.ou.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.$C,null,$CLJS.zD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.zF($CLJS.dJ,$CLJS.lF);
for(var CJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qJ,$CLJS.wJ],null)),DJ=null,EJ=0,FJ=0;;)if(FJ<EJ){var GJ=DJ.X(null,FJ);$CLJS.GF(GJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),$CLJS.Ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nE],null)]));$CLJS.zF(GJ,$CLJS.dJ);FJ+=1}else{var HJ=$CLJS.y(CJ);if(HJ){var IJ=HJ;if($CLJS.Ad(IJ)){var JJ=$CLJS.lc(IJ),Kga=$CLJS.mc(IJ),Lga=JJ,Mga=$CLJS.D(JJ);CJ=Kga;DJ=Lga;EJ=Mga}else{var KJ=$CLJS.A(IJ);$CLJS.GF(KJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.dF],null),$CLJS.Ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nE],null)]));$CLJS.zF(KJ,$CLJS.dJ);CJ=$CLJS.B(IJ);DJ=null;EJ=0}FJ=0}else break}
for(var LJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bJ,$CLJS.VI,$CLJS.nJ,$CLJS.vJ,$CLJS.rJ,$CLJS.aJ,$CLJS.yJ],null)),MJ=null,NJ=0,OJ=0;;)if(OJ<NJ){var Nga=MJ.X(null,OJ);$CLJS.GF(Nga,$CLJS.H([$CLJS.st,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));OJ+=1}else{var PJ=$CLJS.y(LJ);if(PJ){var QJ=PJ;if($CLJS.Ad(QJ)){var RJ=$CLJS.lc(QJ),Oga=$CLJS.mc(QJ),Pga=RJ,Qga=$CLJS.D(RJ);LJ=Oga;MJ=Pga;NJ=Qga}else{var Rga=$CLJS.A(QJ);$CLJS.GF(Rga,$CLJS.H([$CLJS.st,
$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));LJ=$CLJS.B(QJ);MJ=null;NJ=0}OJ=0}else break}$CLJS.GF($CLJS.fJ,$CLJS.H([$CLJS.st,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)]));
for(var SJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.yJ,null,$CLJS.rJ,null,$CLJS.vJ,null,$CLJS.sJ,null,$CLJS.aJ,null,$CLJS.VI,null,$CLJS.bJ,null,$CLJS.nJ,null],null),null)),TJ=null,UJ=0,VJ=0;;)if(VJ<UJ){var Sga=TJ.X(null,VJ);$CLJS.GF(Sga,$CLJS.H([$CLJS.st,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));VJ+=1}else{var WJ=$CLJS.y(SJ);if(WJ){var XJ=WJ;if($CLJS.Ad(XJ)){var YJ=$CLJS.lc(XJ),Tga=$CLJS.mc(XJ),Uga=YJ,Vga=$CLJS.D(YJ);
SJ=Tga;TJ=Uga;UJ=Vga}else{var Wga=$CLJS.A(XJ);$CLJS.GF(Wga,$CLJS.H([$CLJS.st,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)]));SJ=$CLJS.B(XJ);TJ=null;UJ=0}VJ=0}else break}$CLJS.X(xJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,$CLJS.ZI,$CLJS.hJ,$CLJS.mJ],null));
$CLJS.IF($CLJS.oJ,$CLJS.H([$CLJS.st,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.tJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,$CLJS.BE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.zt,"valid timezone ID",$CLJS.Bt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Cj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.cH($CLJS.Jd,$CLJS.UI.tz.names())),$CLJS.LI],null)],null));
$CLJS.IF($CLJS.XI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tJ],null)],null)],null)],
null)]));$CLJS.zF($CLJS.XI,$CLJS.dJ);$CLJS.GF($CLJS.kJ,$CLJS.H([$CLJS.st,$CLJS.cD]));$CLJS.X($I,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.zC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$C,$CLJS.zD],null))}],null)],null));
$CLJS.X(WI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zB,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$I],null)],null)],null)],null));
$CLJS.RG.g($CLJS.cJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.zt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.cJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.zt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.zt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.aw],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fH],null)],null)],null)],null)],null)],null));
$CLJS.VE.m(null,$CLJS.cJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.aw)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.zD:$CLJS.n($CLJS.yF($CLJS.kH,a))?$CLJS.$C:$CLJS.zD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.GI,b))?$CLJS.$C:$CLJS.n($CLJS.nh($CLJS.HI,b))?$CLJS.$C:null:null;if($CLJS.n(a))return a;b=$CLJS.WE(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.zC(c,$CLJS.Vj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(pJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.aw],null),$CLJS.Ls],null));
$CLJS.IF($CLJS.iJ,$CLJS.H([$CLJS.st,$CLJS.zD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nE],null)],null)],null)],null)]));
$CLJS.GF($CLJS.Ny,$CLJS.H([$CLJS.st,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)]));$CLJS.X(YI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ws,$CLJS.hH,$CLJS.zi,$CLJS.lk,$CLJS.eJ,$CLJS.zJ,$CLJS.AJ,$CLJS.Fj,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.eH],null));$CLJS.X(lJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,$CLJS.ZI,$CLJS.hJ,$CLJS.mJ],null));
$CLJS.IF($CLJS.uJ,$CLJS.H([$CLJS.st,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lJ],null)],null)],
null)],null)]));