var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var LY,Wla,NY,Xla,Yla,Zla,PY,ama,bma,cma,dma,SY,TY,UY,VY,XY,fma,YY,ZY,gma,hma,ima,RY,$la,$Y,aZ,ema,cZ;LY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Wla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.j?$CLJS.lj.j(k,l,m):$CLJS.lj.call(null,k,l,m),$CLJS.ll.j?$CLJS.ll.j(k,l,m):$CLJS.ll.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.g?$CLJS.lj.g(k,l):$CLJS.lj.call(null,k,l),$CLJS.ll.g?$CLJS.ll.g(k,l):$CLJS.ll.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.h?$CLJS.lj.h(k):$CLJS.lj.call(null,k),$CLJS.ll.h?$CLJS.ll.h(k):$CLJS.ll.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.o?$CLJS.lj.o():$CLJS.lj.call(null),$CLJS.ll.o?$CLJS.ll.o():$CLJS.ll.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.lj,m,t,u,v),$CLJS.Se.N($CLJS.ll,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.MY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
NY=function(a){return $CLJS.ZL($CLJS.tW)(a,$CLJS.Cf,$CLJS.Cf)};Xla=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.gG,b),$CLJS.pt(2,2,c))};Yla=function(a,b){var c=$CLJS.em(a,$CLJS.PQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.Jt.g?$CLJS.Jt.g(c,b):$CLJS.Jt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.PQ,b)}return a};Zla=function(a){return $CLJS.gf.g($CLJS.UV(a),$CLJS.VV(a))};
$CLJS.OY=function(a,b){return $CLJS.FW(a)&&$CLJS.E.g($CLJS.A(a),b)};PY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Xs,null,$CLJS.sl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Du(2,c):$CLJS.Du(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?LY(2,c):LY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.DX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Pk.h($CLJS.zl),l):$CLJS.xd(k)?$CLJS.OA($CLJS.Wa,l):l}):$CLJS.Gk.g(a,f)};
ama=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return PY(b,$la,d)},a,Zla(a))};
bma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.yL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Wla(),$CLJS.lf($CLJS.Ok.j(QY,$CLJS.A,$CLJS.ll),$CLJS.at.h(NY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=PY(d,f,k),m=$CLJS.RA($CLJS.My);if($CLJS.n($CLJS.QA("metabase.lib.convert",m))){var t=$CLJS.HB.l($CLJS.H([$CLJS.Hz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.HB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.dM(NY(d));$CLJS.PA("metabase.lib.convert",
m,$CLJS.CW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Hz,z.call(x,$CLJS.H([C]))])),$CLJS.HB.l($CLJS.H([$CLJS.wz,$CLJS.DW($CLJS.A($CLJS.ku(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=ama(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.yL.h(a))-1))return a;b+=1}else a=$CLJS.Rk.N(a,$CLJS.yL,$CLJS.R,b,d)}};
cma=function(a){var b=$CLJS.QW();return $CLJS.Qk.g(function(c){return $CLJS.E.g($CLJS.eF.h(c),"__join")?$CLJS.Rk.j(c,$CLJS.eF,b):c},a)};dma=function(a){return"string"===typeof $CLJS.nP.h(a)?$CLJS.Gk.g($CLJS.R.j(a,$CLJS.WV,$CLJS.OW($CLJS.nP.h(a))),$CLJS.nP):a};SY=function(a,b){var c=$CLJS.J.j(a,b,RY);if($CLJS.E.g(c,RY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[ema,a,$CLJS.cx,b],null));return c};
TY=function(){return $CLJS.Pk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};UY=function(a){return $CLJS.eg.j($CLJS.N,TY(),a)};VY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Ok.g(TY(),$CLJS.Pk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ei)})),a))};
XY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.WY),c);a=$CLJS.E.g(a,$CLJS.aG)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(VY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DM,a,b],null):a};fma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Ok.g($CLJS.hf.h(function(b){return $CLJS.JA(b,$CLJS.MB)}),$CLJS.hf.h($CLJS.WY)),$CLJS.KW.h(a))};
YY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.yL);a=$CLJS.A($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.WY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.RQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.$O,fma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.wV.h(c)],null)},null,a));return $CLJS.n($CLJS.sQ.h(a))?$CLJS.HV(a,new $CLJS.h(null,1,[$CLJS.sQ,$CLJS.OF],null)):a};
ZY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.bX(a,b,$CLJS.Ok.g($CLJS.WY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.bX(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Gs],null),$CLJS.hf.h($CLJS.WY),e)}):d;return $CLJS.HV(a,$CLJS.Ee([b,c]))};gma=function(a){var b=$CLJS.WV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Gk.g(a,$CLJS.WV),$CLJS.nP,["card__",$CLJS.p.h(b)].join("")):a};
hma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);ima=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);RY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);$la=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);$Y=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
aZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.bZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);ema=new $CLJS.M(null,"m","m",1632677161);cZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var QY,jma,kma,lma,mma,nma,oma,pma,qma,rma,sma;$CLJS.dZ=$CLJS.N;$CLJS.eZ=$CLJS.N;QY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.qW,null,$CLJS.aF,null,$CLJS.MN,null,$CLJS.hQ,null,$CLJS.fG,null,$CLJS.YR,null,$CLJS.gG,null],null),null);jma=$CLJS.Xe($CLJS.N);kma=$CLJS.Xe($CLJS.N);lma=$CLJS.Xe($CLJS.N);mma=$CLJS.Xe($CLJS.N);nma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.nC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.fZ=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.lC,nma,jma,kma,lma,mma);$CLJS.fZ.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.xW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.fZ),d))}else b=a;return b});$CLJS.fZ.m(null,$CLJS.pW,function(a){return a});
$CLJS.fZ.m(null,$CLJS.cW,function(a){var b=$CLJS.fZ.h($CLJS.gG.h(a)),c=$CLJS.Fe($CLJS.Qk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.HW($CLJS.fZ.h(t),u)},$CLJS.hQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.IE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.eZ,k=$CLJS.dZ;$CLJS.eZ=d;$CLJS.dZ=e;try{var l=Xla(dma(a),b,$CLJS.H([$CLJS.hQ,c])),m=$CLJS.eb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Rk.j(t,u,$CLJS.fZ)},l,$CLJS.Hk.l(QY,$CLJS.gG,$CLJS.H([$CLJS.hQ])));return $CLJS.n($CLJS.MN.h(m))?$CLJS.Rk.j(m,$CLJS.MN,cma):m}finally{$CLJS.dZ=k,$CLJS.eZ=f}});$CLJS.fZ.m(null,$CLJS.jW,function(a){return Yla(a,function(b){return $CLJS.bX(b,$CLJS.LF,$CLJS.fZ)})});
$CLJS.fZ.m(null,$CLJS.tL,function(a){a=$CLJS.Rk.j($CLJS.Rk.j(a,$CLJS.BL,$CLJS.fZ),$CLJS.yL,$CLJS.fZ);var b=$CLJS.n($CLJS.aF.h(a))?$CLJS.Rk.j(a,$CLJS.aF,function(c){return $CLJS.Ll(c)?$CLJS.Qk.g($CLJS.fZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.eF.h(a))?$CLJS.R.j(b,$CLJS.eF,"__join"):b});$CLJS.fZ.m(null,$CLJS.tB,function(a){return $CLJS.Qk.g($CLJS.fZ,a)});
$CLJS.fZ.m(null,$CLJS.vB,function(a){return $CLJS.n($CLJS.lj.h(a))?bma($CLJS.R.j($CLJS.Rk.j($CLJS.LW(a),$CLJS.yL,function(b){return $CLJS.Qk.g($CLJS.fZ,b)}),$CLJS.bZ,!0)):$CLJS.Jt(a,$CLJS.fZ)});$CLJS.fZ.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,a,b],null))});
$CLJS.fZ.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.HV(a,new $CLJS.h(null,3,[$CLJS.mD,$CLJS.zB,$CLJS.MO,$CLJS.Uj,$CLJS.fS,$CLJS.DE],null));var c=$CLJS.R.j;var d=$CLJS.Ei.h(a);$CLJS.n(d)||(d=$CLJS.zB.h(a),d=$CLJS.n(d)?d:$CLJS.WE(b));a=c.call($CLJS.R,a,$CLJS.Ei,d);return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null))});
$CLJS.fZ.m(null,$CLJS.aG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,$CLJS.Gk.g(c,$CLJS.ci),$CLJS.Qk.g($CLJS.fZ,b)],null);b=$CLJS.xW(b);return null!=a?$CLJS.be.g(b,$CLJS.fZ.h(a)):b});$CLJS.fZ.m(null,$CLJS.wB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.fZ.m(null,$CLJS.gG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,SY($CLJS.eZ,c)],null))});$CLJS.fZ.m(null,$CLJS.DM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.fZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.fl.l($CLJS.H([c,a]))],null),d)});oma=$CLJS.Xe($CLJS.N);pma=$CLJS.Xe($CLJS.N);
qma=$CLJS.Xe($CLJS.N);rma=$CLJS.Xe($CLJS.N);sma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.nC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.WY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.lC,sma,oma,pma,qma,rma);
$CLJS.WY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.WY,d);a=VY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.WY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.Jt(UY(a),$CLJS.WY):
a;return a});for(var gZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[cZ,aZ],null)),hZ=null,iZ=0,jZ=0;;)if(jZ<iZ){var tma=hZ.X(null,jZ);$CLJS.zF(tma,$Y);jZ+=1}else{var kZ=$CLJS.y(gZ);if(kZ){var lZ=kZ;if($CLJS.Ad(lZ)){var mZ=$CLJS.lc(lZ),uma=$CLJS.mc(lZ),vma=mZ,wma=$CLJS.D(mZ);gZ=uma;hZ=vma;iZ=wma}else{var xma=$CLJS.A(lZ);$CLJS.zF(xma,$Y);gZ=$CLJS.B(lZ);hZ=null;iZ=0}jZ=0}else break}
for(var nZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Zx,$CLJS.lG,$CLJS.AG,$CLJS.XF,$CLJS.ik,$CLJS.FG,$CLJS.Yn,$CLJS.cG,$CLJS.yG,$CLJS.GG,$CLJS.EG,$CLJS.LG],null)),oZ=null,pZ=0,qZ=0;;)if(qZ<pZ){var yma=oZ.X(null,qZ);$CLJS.zF(yma,cZ);qZ+=1}else{var rZ=$CLJS.y(nZ);if(rZ){var sZ=rZ;if($CLJS.Ad(sZ)){var tZ=$CLJS.lc(sZ),zma=$CLJS.mc(sZ),Ama=tZ,Bma=$CLJS.D(tZ);nZ=zma;oZ=Ama;pZ=Bma}else{var Cma=$CLJS.A(sZ);$CLJS.zF(Cma,cZ);nZ=$CLJS.B(sZ);oZ=null;pZ=0}qZ=0}else break}
for(var uZ=$CLJS.y($CLJS.yf([$CLJS.ds,$CLJS.st,$CLJS.es,$CLJS.MG,$CLJS.aG,$CLJS.uG,$CLJS.SF,$CLJS.OG,$CLJS.JF,$CLJS.eG,$CLJS.NF,$CLJS.pG,$CLJS.wG,$CLJS.PF,$CLJS.gJ,$CLJS.iJ,$CLJS.Ny,$CLJS.cJ,$CLJS.kJ,$CLJS.XI,$CLJS.oJ,$CLJS.bJ,$CLJS.VI,$CLJS.nJ,$CLJS.vJ,$CLJS.rJ,$CLJS.aJ,$CLJS.yJ,$CLJS.qJ,$CLJS.wJ,$CLJS.RF,$CLJS.KF,$CLJS.PG,$CLJS.gI,$CLJS.tG,$CLJS.Xx,$CLJS.HG,$CLJS.JG,$CLJS.QG,$CLJS.Mt,$CLJS.St],!0)),vZ=null,wZ=0,xZ=0;;)if(xZ<wZ){var Dma=vZ.X(null,xZ);$CLJS.zF(Dma,aZ);xZ+=1}else{var yZ=$CLJS.y(uZ);
if(yZ){var zZ=yZ;if($CLJS.Ad(zZ)){var AZ=$CLJS.lc(zZ),Ema=$CLJS.mc(zZ),Fma=AZ,Gma=$CLJS.D(AZ);uZ=Ema;vZ=Fma;wZ=Gma}else{var Hma=$CLJS.A(zZ);$CLJS.zF(Hma,aZ);uZ=$CLJS.B(zZ);vZ=null;wZ=0}xZ=0}else break}$CLJS.WY.m(null,$Y,function(a){return XY(a)});$CLJS.WY.m(null,$CLJS.vB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Ok.g(TY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.WY.h(b)],null)})),a)});
$CLJS.WY.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=VY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,SY($CLJS.dZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.BV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.WY.m(null,$CLJS.tB,function(a){return $CLJS.Qk.g($CLJS.WY,a)});$CLJS.WY.m(null,$CLJS.rG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,$CLJS.WY.h(a),VY(b)],null)});
$CLJS.WY.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=VY($CLJS.HV(b,new $CLJS.h(null,3,[$CLJS.zB,$CLJS.mD,$CLJS.Uj,$CLJS.MO,$CLJS.DE,$CLJS.fS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null)});
$CLJS.WY.m(null,$CLJS.tL,function(a){var b=UY(a);a=0==$CLJS.eF.h(a).lastIndexOf("__join",0)?$CLJS.Gk.g(b,$CLJS.eF):b;return $CLJS.fl.l($CLJS.H([$CLJS.Jt($CLJS.Gk.l(a,$CLJS.yL,$CLJS.H([$CLJS.BL])),$CLJS.WY),ZY($CLJS.dm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.BL],null)),$CLJS.BL,$CLJS.$R),YY(a)]))});
$CLJS.WY.m(null,$CLJS.cW,function(a){var b=$CLJS.gG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.eZ,e=$CLJS.dZ;$CLJS.eZ=c;$CLJS.dZ=b;try{return $CLJS.eb(function(f,k){return $CLJS.bX(f,
k,$CLJS.WY)},ZY($CLJS.bX($CLJS.bX(gma(UY(a)),$CLJS.gG,function(f){return $CLJS.Qk.g(XY,f)}),$CLJS.hQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.WY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GW(C),$CLJS.E.g($CLJS.Cj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.WY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GW(x),$CLJS.E.g($CLJS.Cj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.qW,$CLJS.WF),$CLJS.Hk.l(QY,$CLJS.gG,$CLJS.H([$CLJS.qW,$CLJS.hQ])))}finally{$CLJS.dZ=e,$CLJS.eZ=d}});$CLJS.WY.m(null,$CLJS.jW,function(a){return $CLJS.Jt(UY(a),$CLJS.WY)});
$CLJS.WY.m(null,$CLJS.pW,function(a){try{var b=UY(a),c=$CLJS.DN.h(b),d=YY(b),e=$CLJS.E.g($CLJS.Dj.h($CLJS.id($CLJS.yL.h(a))),$CLJS.jW)?$CLJS.sQ:$CLJS.OF;return $CLJS.fl.l($CLJS.H([$CLJS.Gk.l(b,$CLJS.yL,$CLJS.H([$CLJS.DN,$CLJS.bZ])),function(){var k=$CLJS.Ee([$CLJS.lj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.DN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.BV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.OF,a],null),f);}});
$CLJS.BZ=function(){function a(d,e,f){f=$CLJS.KY(null,$CLJS.Uz(f,$CLJS.H([$CLJS.yi,!0])));var k=$CLJS.NW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.gG);k=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.IE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.eZ,t=$CLJS.dZ;$CLJS.eZ=k;$CLJS.dZ=l;try{try{return $CLJS.fZ.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.BV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.OF,d,$CLJS.ML,e,ima,f,hma,$CLJS.eZ],null),u);}}finally{$CLJS.dZ=t,$CLJS.eZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();