var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var yra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.zB);return $CLJS.Re(function(c){return $CLJS.zC(b,c)},$CLJS.tF)},I7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.zF($CLJS.oG,I7);$CLJS.zF($CLJS.hG,I7);
$CLJS.J0.m(null,$CLJS.YR,function(a,b){var c=$CLJS.Fe($CLJS.YR.h($CLJS.NW(a,b)));return $CLJS.n(c)?$CLJS.rE("Sorted by {0}",$CLJS.H([$CLJS.i2($CLJS.TE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.g0.v(a,b,v,$CLJS.h0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);
return $CLJS.ee($CLJS.g0.v(a,b,t,$CLJS.h0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.G0.m(null,I7,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.g0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "asc":return $CLJS.rE("{0} ascending",$CLJS.H([a]));case "desc":return $CLJS.rE("{0} descending",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.N0.m(null,I7,function(a,b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R.j($CLJS.j0.j(a,b,c),$CLJS.u0,d)});var J7,zra=$CLJS.Xe($CLJS.N),Ara=$CLJS.Xe($CLJS.N),Bra=$CLJS.Xe($CLJS.N),Cra=$CLJS.Xe($CLJS.N),Dra=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.nC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));J7=new $CLJS.di($CLJS.Fh.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.lC,Dra,zra,Ara,Bra,Cra);J7.m(null,I7,function(a){return $CLJS.xW(a)});
J7.m(null,$CLJS.ci,function(a){if(null==a)throw $CLJS.hi($CLJS.TE("Can''t order by nil"),$CLJS.N);return $CLJS.xW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,$CLJS.yW(a)],null))});
$CLJS.K7=function(){function a(d,e){d=J7.h(d);e=$CLJS.n(e)?e:$CLJS.oG;return $CLJS.R.j($CLJS.Df(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.oG):c.call(null,d,$CLJS.oG)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.L7=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=J7.h(k);return $CLJS.n(l)?$CLJS.R.j($CLJS.Df(t),0,l):t}();return $CLJS.YW.l(e,f,$CLJS.Rk,$CLJS.H([$CLJS.YR,function(t){return $CLJS.be.g($CLJS.Df(t),m)}]))}function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.M7=function(){function a(d,e){return $CLJS.Fe($CLJS.J.g($CLJS.NW(d,e),$CLJS.YR))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.N7=function(){function a(d,e){var f=$CLJS.Fe($CLJS.V5.g(d,e)),k=$CLJS.Fe($CLJS.H4.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.gf.g(f,k):function(){var t=$CLJS.NW(d,e);return $CLJS.R0.v(d,e,t,new $CLJS.h(null,1,[$CLJS.D0,!1],null))}();var l=$CLJS.lf(yra,f);f=$CLJS.hf.g(function(t){$CLJS.I(t,0,null);$CLJS.I(t,1,null);return $CLJS.I(t,2,null)},$CLJS.M7.g(d,e));if($CLJS.td(l))return null;if($CLJS.td(f))return $CLJS.Df(l);var m=$CLJS.eg.j($CLJS.N,$CLJS.Ok.g($CLJS.hf.h($CLJS.yW),$CLJS.MY(function(t,u){u=
$CLJS.s2.v(d,e,u,l);return $CLJS.n(u)?new $CLJS.P(null,2,5,$CLJS.Q,[u,t],null):null})),f);return $CLJS.Qk.g(function(t){var u=m.h?m.h(t):m.call(null,t);return $CLJS.n(u)?$CLJS.R.j(t,$CLJS.q0,u):t},l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.O7=new $CLJS.h(null,2,[$CLJS.oG,$CLJS.hG,$CLJS.hG,$CLJS.oG],null);