var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var $L,aM,bM,Aha,Bha,fM,Cha,iM,jM,kM,lM,Dha,mM,nM,pM,qM,cM,rM,Eha,sM,Fha,tM,uM,vM,Gha,wM,xM,Hha,yM,Iha,zM,AM,Jha,CM,Kha,Lha,Mha,EM,FM,GM,HM,IM,JM,KM,LM,Nha,MM,NM,OM,QM,RM,Oha,SM,TM,UM,VM,Pha,WM,XM,YM,ZM,Qha,$M,aN,bN,cN,Rha,dN,Sha,eN,fN,gN,Tha,hN,iN,jN,kN,mN,nN,Uha,oN,pN,Vha,qN,rN,sN,tN,uN,Wha,vN,Xha,wN,xN,zN,Yha,AN,BN,CN,Zha,FN,GN,$ha,aia,HN,JN,KN,LN,bia,ON,PN,cia,dia,eia,QN,RN,SN,TN,UN,VN,fia,WN,gia,XN,YN,$N,bO,cO,dO,eO,hia,iia,gO,jia,kia,hO,iO,jO,kO,lO,lia,mO,mia,nia,nO,oO,pO,qO,rO,
oia,pia,qia,sO,ria,tO,sia,vO,wO,tia,yO,zO,AO,BO,uia,EO,via,wia,FO,GO,HO,xia,IO,JO,KO,LO,yia,NO,OO,PO,QO,RO,zia,SO,TO,Aia,UO,VO,WO,XO,YO,ZO,aP,Bia,bP,Cia,cP,dP,eP,Dia,Eia,Fia,gP,hP,iP,Gia,jP,kP,lP,mP,Hia,Iia,oP,Jia,Kia,pP,qP,rP,sP,tP,Lia,uP,vP,wP,xP,Mia,yP,zP,AP,BP,DP,Nia,EP,FP,Oia,GP,HP,IP,Pia,JP,Qia,LP,Ria,MP,NP,OP,PP,QP,RP,SP,Sia,Tia,Uia,TP,Via,UP,VP,Wia,Xia,WP,XP,YP,Yia,ZP,Zia,$P,$ia,aja,aQ,bja,cja,dja,dQ,eQ,eja,fQ,gQ,jQ,fja,kQ,gja,lQ,mQ,nQ,hja,ija,oQ,pQ,qQ,rQ,jja,tQ,uQ,kja,vQ,wQ,xQ,yQ,zQ,AQ,BQ,
CQ,lja,DQ,EQ,mja,FQ,nja,GQ,HQ,IQ,oja,JQ,KQ,pja,qja,LQ,MQ,NQ,rja,sja,OQ,tja,uja,vja,QQ,wja,SQ,xja,yja,TQ,UQ,zja,Aja,Bja,VQ,WQ,XQ,YQ,ZQ,$Q,aR,Cja,bR,cR,Dja,dR,Eja,Fja,Gja,eR,fR,gR,Hja,hR,iR,Ija,jR,Jja,kR,Kja,Lja,mR,nR,oR,pR,qR,Mja,rR,sR,Nja,tR,uR,vR,wR,xR,yR,zR,AR,Oja,BR,CR,ER,FR,GR,HR,IR,JR,KR,LR,MR,NR,Pja,OR,PR,QR,RR,SR,Qja,TR,UR,VR,WR,Rja,ZR,Sja,Tja,aS,Uja,bS,Vja,cS,Wja,Xja,dS,Yja,eS,gS,hS,Zja,$ja,lS,mS,aka,bka,nS,oS,pS,qS,rS,cka,sS,tS,vS,wS,dka;
$CLJS.ZL=function(a){var b=$CLJS.xo.g(a,null),c=$CLJS.vF(b,$CLJS.Vs,function(d){return $CLJS.wn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.pl,b,$CLJS.Cj,k,$CLJS.at,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
$L=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};aM=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.zn($CLJS.xo.g($CLJS.pl.h(a),null));if($CLJS.n(b)){var e=$L($CLJS.Bt.h(b),c);$CLJS.n(e)?(e=$CLJS.Hq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:$L($CLJS.zt.h(b),c)}return null};bM=function(a,b){return $CLJS.vd(a)||$CLJS.Il(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Aha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.pl),d=$CLJS.J.g(a,$CLJS.lj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.at,$CLJS.kea),f=$CLJS.J.j(b,$CLJS.fea,!0),k=$CLJS.J.g(b,$CLJS.kC),l=$CLJS.J.j(b,$CLJS.iC,$CLJS.gC),m=aM(a,$CLJS.Tq.h(c),k,b);if($CLJS.n(m))return m;m=aM(a,$CLJS.mt.h(c),k,b);if($CLJS.n(m))return m;m=aM(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=aM(a,function(){var t=$CLJS.eo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=aM(a,$CLJS.Tq.h(c),
l,b);if($CLJS.n(m))return m;m=aM(a,$CLJS.mt.h(c),l,b);if($CLJS.n(m))return m;d=aM(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=aM(a,function(){var t=$CLJS.eo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?aM(a,e.h?e.h($CLJS.eC):e.call(null,$CLJS.eC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?aM(a,e.h?e.h($CLJS.eC):e.call(null,$CLJS.eC),l,b):f};
Bha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.ll.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.pl),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.kC);l=$CLJS.J.j(l,$CLJS.iC,$CLJS.gC);k=$CLJS.Tq.h(k);f=$L($CLJS.hC.h(k),f);f=$CLJS.n(f)?f:$L($CLJS.hC.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Aha(b,c)],null)};
$CLJS.dM=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Cj);a=$CLJS.J.g(b,$CLJS.at);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.gea,$CLJS.Pi),f=$CLJS.J.j(d,$CLJS.iea,Bha);return $CLJS.n(a)?$CLJS.eb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Pi,m);l=e.h?e.h(l):e.call(null,l);return cM(k,c,t,l)},null,a):null};
fM=function(a,b,c){var d=$CLJS.IA($CLJS.q($CLJS.jE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.jE,$CLJS.eM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.gM=function(a){return fM($CLJS.wk,a,function(){return $CLJS.wF.h(a)})};Cha=function(){var a=hM;return fM($CLJS.Vs,a,function(){var b=$CLJS.wF.h(a),c=$CLJS.ZL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
iM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};jM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,iM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,iM(c)],null);default:return iM(a)}}else return iM(a)};
kM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
lM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(kM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,jM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,jM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.pt(2,2,b))}())],null)};Dha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
mM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.Ni,Dha,$CLJS.zt,["valid instance of one of these MBQL clauses: ",$CLJS.Kt(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
nM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YL],null)],null)};$CLJS.oM={};pM={};qM={};$CLJS.eM=function eM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=eM.j?eM.j(k,f,c):eM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
cM=function cM(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=bM(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.fC.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=bM(f,c),d=cM.v?cM.v(b,l,k,d):cM.call(null,b,l,k,d),$CLJS.Nl(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Il(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hea],null),cM.v?cM.v(f,b,c,d):cM.call(null,f,b,c,d)):$CLJS.n($CLJS.fC.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.fC,!0],null))};rM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Eha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
sM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Fha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);tM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);uM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);vM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Gha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);wM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);xM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Hha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);yM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Iha=new $CLJS.M(null,"from","from",1815293044);zM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);AM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Jha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.BM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);CM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Kha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Lha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.DM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Mha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);EM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
FM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);GM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);HM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);IM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);JM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
KM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);LM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Nha=new $CLJS.M(null,"lon-max","lon-max",1590224717);MM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);NM=new $CLJS.r(null,"stddev","stddev",775056588,null);OM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.PM=new $CLJS.M(null,"snippet","snippet",953581994);QM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);RM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Oha=new $CLJS.M(null,"lat-field","lat-field",-830652957);SM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);TM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
UM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);VM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Pha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);WM=new $CLJS.M("location","country","location/country",1666636202);XM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
YM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);ZM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Qha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);$M=new $CLJS.M(null,"unary","unary",-989314568);aN=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
bN=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);cN=new $CLJS.r(null,"ceil","ceil",-184398425,null);Rha=new $CLJS.M(null,"lon-min","lon-min",-787291357);dN=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Sha=new $CLJS.M(null,"match","match",1220059550);eN=new $CLJS.r(null,"count-where","count-where",2025939247,null);fN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
gN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Tha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);hN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);iN=new $CLJS.r(null,"sum","sum",1777518341,null);jN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);kN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.lN=new $CLJS.M("date","range","date/range",1647265776);mN=new $CLJS.r(null,"between","between",-1523336493,null);nN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Uha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);oN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);pN=new $CLJS.r(null,"field","field",338095027,null);Vha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
qN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);rN=new $CLJS.r(null,"not-null","not-null",313812992,null);sN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);tN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);uN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Wha=new $CLJS.M(null,"template-tag","template-tag",310841038);
vN=new $CLJS.M(null,"invalid","invalid",412869516);Xha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);wN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);xN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.yN=new $CLJS.M(null,"context","context",-830191113);zN=new $CLJS.r(null,"get-year","get-year",704520253,null);
Yha=new $CLJS.M(null,"format-rows?","format-rows?",992129486);AN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);BN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);CN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.DN=new $CLJS.M(null,"parameters","parameters",-1229919748);Zha=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.EN=new $CLJS.M(null,"card","card",-1430355152);FN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);GN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);$ha=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);aia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);HN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.IN=new $CLJS.M("date","month-year","date/month-year",1948031290);
JN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);KN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);LN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.MN=new $CLJS.M(null,"joins","joins",1033962699);bia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.NN=new $CLJS.M(null,"source-field","source-field",933829534);ON=new $CLJS.r(null,"Field","Field",430385967,null);
PN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);cia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);dia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);eia=new $CLJS.M(null,"items","items",1031954938);QN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);RN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
SN=new $CLJS.M(null,"more","more",-2058821800);TN=new $CLJS.M(null,"first-clause","first-clause",-20953491);UN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);VN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);fia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);WN=new $CLJS.r(null,"contains","contains",-1977535957,null);
gia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);XN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);YN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.ZN=new $CLJS.M(null,"widget-type","widget-type",1836256899);$N=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.aO=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
bO=new $CLJS.r(null,"is-null","is-null",-356519403,null);cO=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);dO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);eO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);hia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.fO=new $CLJS.M(null,"required","required",1807647006);iia=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
gO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);jia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);kia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);hO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);iO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);jO=new $CLJS.M("string","contains","string/contains",1602423827);kO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
lO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);lia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);mO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);mia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);nia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
nO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);oO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);pO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);qO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);rO=new $CLJS.r(null,"share","share",1051097594,null);
oia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);pia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);qia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);sO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);ria=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
tO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);sia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.uO=new $CLJS.M(null,"collection","collection",-683361892);vO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);wO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.xO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);tia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);yO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);zO=new $CLJS.r(null,"metric","metric",2049329604,null);AO=new $CLJS.r(null,"concat","concat",-467652465,null);BO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.CO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
uia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.DO=new $CLJS.M("date","relative","date/relative",25987732);EO=new $CLJS.M("location","city","location/city",-1746973325);via=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);wia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);FO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);GO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
HO=new $CLJS.M("number","between","number/between",97700581);xia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);IO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);JO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);KO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);LO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.MO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);yia=new $CLJS.M(null,"metric-id","metric-id",-686486942);NO=new $CLJS.r(null,"*","*",345799209,null);OO=new $CLJS.r(null,"+","+",-740910886,null);PO=new $CLJS.r(null,"-","-",-471816912,null);QO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);RO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);zia=new $CLJS.M(null,"question","question",-1411720117);SO=new $CLJS.r(null,"asc","asc",1997386096,null);
TO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Aia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);UO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);VO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);WO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);XO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);YO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
ZO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.$O=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);aP=new $CLJS.M("string","ends-with","string/ends-with",302681156);Bia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);bP=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Cia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
cP=new $CLJS.r(null,"and","and",668631710,null);dP=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);eP=new $CLJS.r(null,"round","round",-645002441,null);Dia=new $CLJS.M(null,"to","to",192099007);$CLJS.fP=new $CLJS.M("date","single","date/single",1554682003);Eia=new $CLJS.M(null,"action-id","action-id",-1727958578);Fia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);gP=new $CLJS.r(null,"exp","exp",1378825265,null);
hP=new $CLJS.r(null,"Filter","Filter",-424893332,null);iP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Gia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);jP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);kP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);lP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
mP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.nP=new $CLJS.M(null,"source-table","source-table",-225307692);Hia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Iia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);oP=new $CLJS.r(null,"floor","floor",-772394748,null);Jia=new $CLJS.M(null,"middleware","middleware",1462115504);
Kia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);pP=new $CLJS.M(null,"requires-features","requires-features",-101116256);qP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);rP=new $CLJS.M(null,"clause-name","clause-name",-996419059);sP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);tP=new $CLJS.r(null,"now","now",-9994004,null);
Lia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);uP=new $CLJS.r(null,"not","not",1044554643,null);vP=new $CLJS.r(null,"avg","avg",1837937727,null);wP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);xP=new $CLJS.M(null,"max-results","max-results",-32858165);Mia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);yP=new $CLJS.r(null,"case","case",-1510733573,null);
zP=new $CLJS.r(null,"distinct","distinct",-148347594,null);AP=new $CLJS.r(null,"get-second","get-second",-425414791,null);BP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.CP=new $CLJS.M(null,"join-alias","join-alias",1454206794);DP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Nia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
EP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);FP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Oia=new $CLJS.M(null,"original","original",-445386197);GP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);HP=new $CLJS.r(null,"abs","abs",1394505050,null);IP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Pia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
JP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Qia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.KP=new $CLJS.M(null,"date","date",-1463434462);LP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Ria=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);MP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
NP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);OP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);PP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);QP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);RP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);SP=new $CLJS.r(null,"or","or",1876275696,null);
Sia=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Tia=new $CLJS.M(null,"constraints","constraints",422775616);Uia=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);TP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Via=new $CLJS.M(null,"csv-download","csv-download",2141432084);UP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
VP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Wia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Xia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);WP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);XP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
YP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Yia=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);ZP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Zia=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);$P=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);$ia=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
aja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);aQ=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);bja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);cja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);dja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.bQ=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.cQ=new $CLJS.M(null,"database","database",1849087575);dQ=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);eQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);eja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);fQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
gQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.hQ=new $CLJS.M(null,"expressions","expressions",255689909);jQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);fja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);kQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
gja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);lQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);mQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);nQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);hja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);ija=new $CLJS.M(null,"action","action",-811238024);
oQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);pQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);qQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);rQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.sQ=new $CLJS.M(null,"native","native",-613060878);jja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);tQ=new $CLJS.M(null,"page","page",849072397);
uQ=new $CLJS.r(null,"length","length",-2065447907,null);kja=new $CLJS.M(null,"dashboard","dashboard",-631747508);vQ=new $CLJS.r(null,"get-week","get-week",752472178,null);wQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);xQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);yQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);zQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
AQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);BQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);CQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);lja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);DQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);EQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
mja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);FQ=new $CLJS.M(null,"internal","internal",-854870097);nja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);GQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);HQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
IQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);oja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);JQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);KQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);pja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
qja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);LQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);MQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);NQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
rja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);sja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);OQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.PQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);tja=new $CLJS.M(null,"public-question","public-question",629369976);uja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
vja=new $CLJS.M(null,"binary","binary",-1802232288);QQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);wja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.RQ=new $CLJS.M(null,"source-query","source-query",198004422);SQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);xja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
yja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);TQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);UQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);zja=new $CLJS.M(null,"executed-by","executed-by",-739811161);Aja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Bja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);VQ=new $CLJS.M(null,"amount","amount",364489504);WQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);XQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);YQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);ZQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
$Q=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);aR=new $CLJS.r(null,"trim","trim",-1880116002,null);Cja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);bR=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);cR=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Dja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
dR=new $CLJS.M("string","\x3d","string/\x3d",983744235);Eja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Fja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Gja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);eR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);fR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
gR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Hja=new $CLJS.M(null,"lat-min","lat-min",1630784161);hR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);iR=new $CLJS.r(null,"inside","inside",-681932758,null);Ija=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);jR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Jja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);kR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Kja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Lja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.lR=new $CLJS.M(null,"card-id","card-id",-1770060179);mR=new $CLJS.M(null,"variadic","variadic",882626057);
nR=new $CLJS.r(null,"upper","upper",1886775433,null);oR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);pR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);qR=new $CLJS.r(null,"optional","optional",-600484260,null);Mja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);rR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
sR=new $CLJS.M(null,"sugar","sugar",-73788488);Nja=new $CLJS.M(null,"lat-max","lat-max",841568226);tR=new $CLJS.r(null,"power","power",702679448,null);uR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);vR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);wR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
xR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);yR=new $CLJS.r(null,"median","median",-2084869638,null);zR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);AR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Oja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);BR=new $CLJS.M(null,"y","y",-1757859776);CR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.DR=new $CLJS.M(null,"binning","binning",1709835866);ER=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);FR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);GR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);HR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);IR=new $CLJS.r(null,"Reference","Reference",2024574086,null);JR=new $CLJS.M(null,"b","b",1482224470);
KR=new $CLJS.M(null,"a","a",-2123407586);LR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);MR=new $CLJS.r(null,"replace","replace",853943757,null);NR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Pja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);OR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
PR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);QR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);RR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);SR=new $CLJS.r(null,"segment","segment",675610331,null);Qja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);TR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
UR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);VR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);WR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Rja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.YR=new $CLJS.M(null,"order-by","order-by",1527318070);ZR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Sja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Tja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.$R=new $CLJS.M(null,"condition","condition",1668437652);aS=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Uja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);bS=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Vja=new $CLJS.M(null,"card-name","card-name",-2035606807);cS=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Wja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Xja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);dS=new $CLJS.r(null,"log","log",45015523,null);Yja=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
eS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.fS=new $CLJS.M(null,"database_type","database_type",-54700895);gS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);hS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.iS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.jS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.kS=new $CLJS.M("date","all-options","date/all-options",549325958);
Zja=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);$ja=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);lS=new $CLJS.M("location","state","location/state",-114378652);mS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);aka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);bka=new $CLJS.M(null,"lon-field","lon-field",517872067);
nS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);oS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);pS=new $CLJS.M(null,"numeric","numeric",-1495594714);qS=new $CLJS.r(null,"variable","variable",1359185035,null);rS=new $CLJS.r(null,"lower","lower",-1534114948,null);cka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
sS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);tS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.uS=new $CLJS.M(null,"limit","limit",-1355822363);vS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);wS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);dka=new $CLJS.M(null,"pulse","pulse",-244494476);var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BE],null),yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KE],null),zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xE],null),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tE],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.FE],null),CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pL],null),eka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fL],null),fka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$K],null),DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.PI],null),ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TI],null),FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KI],null),GS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.oi,null,$CLJS.gu,null,$CLJS.nj,null,$CLJS.ci,null,$CLJS.Fi,null,$CLJS.wj,null,$CLJS.lk,null,$CLJS.Fj,null,$CLJS.ji,null,$CLJS.Ej,null,$CLJS.pi,null,$CLJS.zi,null],null),null),HS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.pk,null,$CLJS.dH,null,$CLJS.ci,null,$CLJS.vi,null,$CLJS.gH,null,$CLJS.hj,null,$CLJS.Ti,null],null),null),
IS=$CLJS.hu.g(GS,HS),gka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"date bucketing unit"],null)],null),GS),hka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"time bucketing unit"],null)],null),HS),JS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"datetime bucketing unit"],null)],null),IS),KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tJ],null),ika=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,
1,[$CLJS.zt,"temporal extract unit"],null),$CLJS.hH,$CLJS.zi,$CLJS.lk,$CLJS.eJ,$CLJS.zJ,$CLJS.AJ,$CLJS.Fj,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.eH],null),jka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"datetime-diff unit"],null),$CLJS.dH,$CLJS.Ti,$CLJS.pk,$CLJS.gu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null),LS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"temporal-extract week extraction mode"],null),$CLJS.ZI,$CLJS.hJ,$CLJS.mJ],null),MS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Ti,$CLJS.pk,$CLJS.gu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null),TS,lka,aT,bT,cT,dT,eT,fT,gT,SU,nka,TU,oka,pka,UU,qka,rka,ska;$CLJS.X(bN,lM($CLJS.iJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.aw],null),$CLJS.Ls],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,MS],null)])));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null);
$CLJS.X(GP,lM($CLJS.gJ,$CLJS.H(["n",$CLJS.Ls,"unit",MS])));var kka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GP],null);
$CLJS.X(wN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"valid :absolute-datetime clause",$CLJS.Ni,function(a){if($CLJS.Va(kM($CLJS.cJ,a)))a=vN;else{a=$CLJS.hd(a);var b=$CLJS.gM(DS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.KP:$CLJS.jJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[vN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KP,lM($CLJS.cJ,
$CLJS.H(["date",DS,"unit",gka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,lM($CLJS.cJ,$CLJS.H(["datetime",ES,"unit",JS]))],null)],null));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wN],null);$CLJS.X(LN,lM($CLJS.Ny,$CLJS.H(["time",FS,"unit",hka])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LN],null),QS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.zt,"date or datetime literal"],null),OS,ES,DS],null);
$CLJS.X(wM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.zt,"time literal"],null),PS,FS],null));$CLJS.X(nS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.zt,"temporal literal"],null),QS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null)],null));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nS],null);
$CLJS.X(Dja,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof OS?new $CLJS.Gc(function(){return OS},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,FQ,$CLJS.mj,$CLJS.xk,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nQ,"metabase/mbql/schema.cljc",69,$CLJS.cJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:nQ}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,
$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",29,$CLJS.iJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.iJ,$CLJS.qk,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.aw],null),$CLJS.Ls],null)),$CLJS.ri,$CLJS.U($CLJS.Pj,$CLJS.U(qR,bP))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:EP}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,FQ,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eS,"metabase/mbql/schema.cljc",27,$CLJS.Ny,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ny,$CLJS.Ny,$CLJS.U($CLJS.Pj,Cja),$CLJS.ri,$CLJS.U($CLJS.Pj,uia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:eS}(),PS],null)])));
$CLJS.X(cS,lM($CLJS.Cj,$CLJS.H(["value",$CLJS.Qs,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fS,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ys,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,JS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null)],null)],null)])));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cS],null);$CLJS.X(LO,lM($CLJS.wB,$CLJS.H(["expression-name",xS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,$CLJS.hk],null)])));
TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LO],null);
lka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.ME);c=$CLJS.J.g(c,$CLJS.uE);return $CLJS.E.g(b,$CLJS.uE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.ME);c=$CLJS.J.g(c,$CLJS.LE);return $CLJS.E.g(b,$CLJS.LE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.zt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ME,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"binning strategy"],null),$CLJS.uE,$CLJS.LE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Us,!0],null),AS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.LE,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Al,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Vl)],null)],null)],null)],null)));$CLJS.US=function US(a){switch(arguments.length){case 1:return US.h(arguments[0]);case 2:return US.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.US.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.zB);b=$CLJS.J.g(b,$CLJS.xG);return $CLJS.US.g(a,b)};$CLJS.US.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.zC(a,$CLJS.$C)?GS:$CLJS.zC(a,$CLJS.ZD)?HS:$CLJS.zC(a,$CLJS.zD)?IS:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.US.A=2;
$CLJS.X(rR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.zt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zB,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NN,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,new $CLJS.h(null,
1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,JS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DR,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,lka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.US],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.ME)],null)],null));
$CLJS.X(TR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.zB);return"string"===typeof c?b:!0}],null)],null)}(lM($CLJS.rG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,CS,xS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rR],null)],null)]))));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TR],null);
$CLJS.X(TP,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof TS?new $CLJS.Gc(function(){return TS},$CLJS.od(XN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HN,"metabase/mbql/schema.cljc",60,$CLJS.wB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wB,$CLJS.jS,$CLJS.U($CLJS.Pj,dN),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:HN}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof VS?new $CLJS.Gc(function(){return VS},
$CLJS.od(oO,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.xr,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,$CLJS.ti,$CLJS.jk,$CLJS.nk],["0.39.0",$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pN,"metabase/mbql/schema.cljc",51,$CLJS.rG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:pN}(),VS],null)])));
$CLJS.WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TP],null);$CLJS.X(rM,lM($CLJS.gG,$CLJS.H(["aggregation-clause-index",$CLJS.Ls,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,$CLJS.hk],null)])));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);
$CLJS.X(YM,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof XS?new $CLJS.Gc(function(){return XS},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CR,"metabase/mbql/schema.cljc",23,$CLJS.gG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gG,aka,$CLJS.U($CLJS.Pj,$CLJS.Ls),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:CR}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof TS?new $CLJS.Gc(function(){return TS},
$CLJS.od(XN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",60,$CLJS.wB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wB,$CLJS.jS,$CLJS.U($CLJS.Pj,dN),
$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,$CLJS.hk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:HN}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof VS?new $CLJS.Gc(function(){return VS},$CLJS.od(oO,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.xr,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,
$CLJS.ti,$CLJS.jk,$CLJS.nk],["0.39.0",$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pN,"metabase/mbql/schema.cljc",51,$CLJS.rG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:pN}(),VS],null)])));
var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null),ZS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Mt,null,$CLJS.JG,null,$CLJS.KF,null,$CLJS.PG,null,$CLJS.RF,null,$CLJS.uG,null,$CLJS.aG,null,$CLJS.St,null,$CLJS.HG,null,$CLJS.tG,null,$CLJS.QG,null],null),null),$S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);
$CLJS.X(yM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,1,[$CLJS.Ni,function(a){return"string"===typeof a?$CLJS.Yj:$CLJS.n(kM(ZS,a))?RM:$CLJS.n(kM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[RM,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,$CLJS.WS],null)],null));aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null);
bT=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.NF,null,$CLJS.yJ,null,$CLJS.es,null,$CLJS.uJ,null,$CLJS.rJ,null,$CLJS.eG,null,$CLJS.st,null,$CLJS.JF,null,$CLJS.vJ,null,$CLJS.oJ,null,$CLJS.uG,null,$CLJS.MG,null,$CLJS.sJ,null,$CLJS.wG,null,$CLJS.PF,null,$CLJS.aJ,null,$CLJS.ds,null,$CLJS.SF,null,$CLJS.aG,null,$CLJS.VI,null,$CLJS.Xx,null,$CLJS.bJ,null,$CLJS.nJ,null,$CLJS.fJ,null,$CLJS.OG,null,$CLJS.pG,null],null),null);
cT=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.as,null,$CLJS.Vr,null,$CLJS.zs,null,$CLJS.Bs,null,$CLJS.Xr,null,$CLJS.IG,null,$CLJS.Gs,null,$CLJS.bs,null,$CLJS.Zr,null],null),null);dT=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.Yn,null,$CLJS.GG,null,$CLJS.AG,null,$CLJS.sG,null,$CLJS.LG,null,$CLJS.NG,null,$CLJS.XF,null,$CLJS.cG,null,$CLJS.DM,null,$CLJS.YF,null,$CLJS.FG,null,$CLJS.yG,null,$CLJS.ik,null,$CLJS.Zx,null,$CLJS.lG,null,$CLJS.mG,null,$CLJS.EG,null],null),null);
eT=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.kJ,null,$CLJS.XI,null,$CLJS.wJ,null,$CLJS.ds,null,$CLJS.qJ,null],null),null);fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jR],null);gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JN],null);$CLJS.hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LQ],null);$CLJS.iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pR],null);
$CLJS.X(wR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"numeric expression argument",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.au:$CLJS.n(kM(bT,a))?mP:$CLJS.n(kM(dT,a))?$CLJS.gG:$CLJS.n(kM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.rG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.au,$CLJS.Al],null),new $CLJS.P(null,2,5,$CLJS.Q,[mP,fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,$CLJS.iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rG,
$CLJS.WS],null)],null));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wR],null);
$CLJS.X(GQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"datetime expression argument",$CLJS.Ni,function(a){return $CLJS.n(kM(dT,a))?$CLJS.gG:$CLJS.n(kM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.n(kM(eT,a))?QN:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,$CLJS.iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[QN,$CLJS.hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,QS,$CLJS.WS],null)],null)],null));
var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);
$CLJS.X(jQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"expression argument",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.au:$CLJS.Cd(a)?$CLJS.Ps:$CLJS.n(kM(cT,a))?yQ:$CLJS.n(kM(bT,a))?mP:$CLJS.n(kM(eT,a))?QN:"string"===typeof a?$CLJS.Yj:$CLJS.n(kM(ZS,a))?RM:$CLJS.n(kM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.au,$CLJS.Al],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.Ps],null),new $CLJS.P(null,2,5,$CLJS.Q,[yQ,gT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[mP,fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[QN,$CLJS.hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[RM,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,$CLJS.WS],null)],null));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null);$CLJS.X(gQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.zt,"numeric expression arg or interval"],null),kka,jT],null));
var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null);$CLJS.X(JM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"int greater than zero or numeric expression",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.au:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.au,AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,fT],null)],null));var mka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null);
$CLJS.X(RN,lM($CLJS.uG,$CLJS.H(["a",lT,"b",lT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,lT],null)])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RN],null);$CLJS.X(vM,lM($CLJS.KF,$CLJS.H(["s",aT,"start",mka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,jT],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);$CLJS.X(zQ,lM($CLJS.Xx,$CLJS.H(["s",aT])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);$CLJS.X(XM,lM($CLJS.HG,$CLJS.H(["s",aT])));
var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);$CLJS.X(aN,lM($CLJS.QG,$CLJS.H(["s",aT])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aN],null);$CLJS.X(tN,lM($CLJS.JG,$CLJS.H(["s",aT])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tN],null);$CLJS.X(eO,lM($CLJS.Mt,$CLJS.H(["s",aT])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eO],null);$CLJS.X(OM,lM($CLJS.St,$CLJS.H(["s",aT])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null);
$CLJS.X(vR,lM($CLJS.PG,$CLJS.H(["s",aT,"match",$CLJS.Yj,"replacement",$CLJS.Yj])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vR],null);$CLJS.X(sS,lM($CLJS.RF,$CLJS.H(["a",aT,"b",aT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,aT],null)])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sS],null);$CLJS.X(UO,lM($CLJS.tG,$CLJS.H(["s",aT,"pattern",$CLJS.Yj])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UO],null);
$CLJS.X(RR,lM($CLJS.ds,$CLJS.H(["x",mT,"y",mT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,mT],null)])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null);$CLJS.X(PR,lM($CLJS.st,$CLJS.H(["x",jT,"y",mT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,mT],null)])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PR],null);$CLJS.X(OR,lM($CLJS.MG,$CLJS.H(["x",jT,"y",jT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,jT],null)])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);
$CLJS.X(QR,lM($CLJS.es,$CLJS.H(["x",jT,"y",jT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,jT],null)])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QR],null);$CLJS.X(fQ,lM($CLJS.pG,$CLJS.H(["x",jT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null);$CLJS.X(ZQ,lM($CLJS.NF,$CLJS.H(["x",jT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZQ],null);$CLJS.X(vS,lM($CLJS.wG,$CLJS.H(["x",jT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vS],null);$CLJS.X(kP,lM($CLJS.SF,$CLJS.H(["x",jT])));
var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kP],null);$CLJS.X(qP,lM($CLJS.PF,$CLJS.H(["x",jT,"y",jT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qP],null);$CLJS.X(NP,lM($CLJS.eG,$CLJS.H(["x",jT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.X(KM,lM($CLJS.JF,$CLJS.H(["x",jT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null);$CLJS.X(mQ,lM($CLJS.OG,$CLJS.H(["x",jT])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);
$CLJS.X(jN,lM($CLJS.fJ,$CLJS.H(["datetime-x",kT,"datetime-y",kT,"unit",jka])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null);$CLJS.X(BN,lM($CLJS.uJ,$CLJS.H(["datetime",kT,"unit",ika,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,LS],null)])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null);$CLJS.X(sP,lM($CLJS.bJ,$CLJS.H(["date",kT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sP],null);$CLJS.X(UP,lM($CLJS.yJ,$CLJS.H(["date",kT])));
var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UP],null);$CLJS.X(xM,lM($CLJS.VI,$CLJS.H(["date",kT])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xM],null);$CLJS.X(tS,lM($CLJS.oJ,$CLJS.H(["date",kT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,LS],null)])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tS],null);$CLJS.X(FO,lM($CLJS.nJ,$CLJS.H(["date",kT])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FO],null);$CLJS.X(OP,lM($CLJS.sJ,$CLJS.H(["date",kT])));
var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OP],null);$CLJS.X(fN,lM($CLJS.vJ,$CLJS.H(["datetime",kT])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null);$CLJS.X(KO,lM($CLJS.rJ,$CLJS.H(["datetime",kT])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KO],null);$CLJS.X(ZM,lM($CLJS.aJ,$CLJS.H(["datetime",kT])));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null);$CLJS.X(pO,lM($CLJS.XI,$CLJS.H(["datetime",kT,"to",KS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,KS],null)])));
var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null),WT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"datetime arithmetic unit"],null),$CLJS.gH,$CLJS.dH,$CLJS.Ti,$CLJS.pk,$CLJS.gu,$CLJS.nj,$CLJS.wj,$CLJS.oi,$CLJS.ji],null);$CLJS.X(uM,lM($CLJS.qJ,$CLJS.H(["datetime",kT,"amount",jT,"unit",WT])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null);
$CLJS.X(SM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return lM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.kJ));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);$CLJS.X(tM,lM($CLJS.wJ,$CLJS.H(["datetime",kT,"amount",jT,"unit",WT])));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);
$CLJS.X(LQ,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od($P,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OO,"metabase/mbql/schema.cljc",51,$CLJS.ds,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ds,$CLJS.JE,$CLJS.U($CLJS.Pj,dQ),BR,$CLJS.U($CLJS.Pj,dQ),SN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,dQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:OO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},
$CLJS.od(Lia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[iO,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",67,$CLJS.qJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,oS),VQ,$CLJS.U($CLJS.Pj,
$Q),$CLJS.ri,$CLJS.U($CLJS.Pj,hR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:VP}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(Eha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,
$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[iO,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mS,"metabase/mbql/schema.cljc",72,$CLJS.wJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.wJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,oS),VQ,$CLJS.U($CLJS.Pj,$Q),$CLJS.ri,$CLJS.U($CLJS.Pj,hR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(ZT)?ZT.H:null])):null));
return $CLJS.n(a)?a:mS}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.XI,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",71,$CLJS.XI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XI,$CLJS.jJ,$CLJS.U($CLJS.Pj,oS),Dia,$CLJS.U($CLJS.Pj,ER),Iha,$CLJS.U($CLJS.Pj,$CLJS.U(qR,ER))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:PN}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&
"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(lja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",
45,$CLJS.kJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:tP}(),YT],null)])));$CLJS.$T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);$CLJS.X(zM,lM($CLJS.Gs,$CLJS.H(["first-clause",$CLJS.$T,"second-clause",$CLJS.$T,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,$CLJS.$T],null)])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null);
$CLJS.X(lO,lM($CLJS.zs,$CLJS.H(["first-clause",$CLJS.$T,"second-clause",$CLJS.$T,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,$CLJS.$T],null)])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null);$CLJS.X(UM,lM($CLJS.Bs,$CLJS.H(["clause",$CLJS.$T])));
var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null),dU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,3,[$CLJS.zt,":field or :expression reference or :relative-datetime",$CLJS.Bt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Ni,function(a){return $CLJS.n(kM($CLJS.iJ,a))?$CLJS.iJ:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,$CLJS.WS],null)],null);
$CLJS.X(IQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.zt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.zs,$CLJS.Ps,$CLJS.Al,$CLJS.Yj,RS,dU,lT,SS],null)],null));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);
$CLJS.X(ZO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"order comparable",$CLJS.Ni,function(a){return $CLJS.n(kM($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.zs,$CLJS.Al,$CLJS.Yj,RS,lT,dU],null)],null)],null));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZO],null);
$CLJS.X(HR,lM($CLJS.bs,$CLJS.H(["field",eU,"value-or-field",eU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,eU],null)])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(LR,lM($CLJS.IG,$CLJS.H(["field",eU,"value-or-field",eU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,eU],null)])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);$CLJS.X(GR,lM($CLJS.Zr,$CLJS.H(["field",fU,"value-or-field",fU])));
var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);$CLJS.X(FR,lM($CLJS.Vr,$CLJS.H(["field",fU,"value-or-field",fU])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FR],null);$CLJS.X(uN,lM($CLJS.as,$CLJS.H(["field",fU,"value-or-field",fU])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null);$CLJS.X(AN,lM($CLJS.Xr,$CLJS.H(["field",fU,"value-or-field",fU])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AN],null);$CLJS.X(qQ,lM($CLJS.UF,$CLJS.H(["field",fU,"min",fU,"max",fU])));
var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qQ],null);$CLJS.X(SQ,lM($CLJS.$F,$CLJS.H(["lat-field",fU,"lon-field",fU,"lat-max",fU,"lon-min",fU,"lat-min",fU,"lon-max",fU])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SQ],null);$CLJS.X(dO,lM($CLJS.TF,$CLJS.H(["field",$CLJS.WS])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.X(TO,lM($CLJS.dG,$CLJS.H(["field",$CLJS.WS])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TO],null);$CLJS.X(vO,lM($CLJS.VF,$CLJS.H(["field",$CLJS.WS])));
var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vO],null);$CLJS.X(CM,lM($CLJS.bG,$CLJS.H(["field",$CLJS.WS])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null),sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Ps],null)],null);$CLJS.X(IO,lM($CLJS.iG,$CLJS.H(["field",aT,"string-or-field",aT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,sU],null)])));var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IO],null);
$CLJS.X(BP,lM($CLJS.CG,$CLJS.H(["field",aT,"string-or-field",aT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,sU],null)])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BP],null);$CLJS.X(tO,lM($CLJS.qG,$CLJS.H(["field",aT,"string-or-field",aT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,sU],null)])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tO],null);$CLJS.X(kQ,lM($CLJS.jG,$CLJS.H(["field",aT,"string-or-field",aT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,sU],null)])));
var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null);$CLJS.X(LM,lM($CLJS.DG,$CLJS.H(["field",$CLJS.WS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.Ls,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,$CLJS.aw,$CLJS.cy,$CLJS.ZF],null)],null),"unit",MS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Ps],null)],null)],null)])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);
$CLJS.X(BQ,lM($CLJS.QF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null),xS],null)])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);
$CLJS.X(JN,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(uR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cP,"metabase/mbql/schema.cljc",15,$CLJS.Gs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Gs,TN,$CLJS.U($CLJS.Pj,hP),LP,$CLJS.U($CLJS.Pj,hP),cO,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,hP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:cP}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},
$CLJS.od(PP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",14,$CLJS.zs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zs,TN,$CLJS.U($CLJS.Pj,hP),LP,$CLJS.U($CLJS.Pj,hP),cO,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,hP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:SP}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(CQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",15,$CLJS.Bs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bs,$CLJS.iS,$CLJS.U($CLJS.Pj,hP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:uP}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==
typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(YP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",13,$CLJS.Zr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.rG,$CLJS.U($CLJS.Pj,VN),fR,$CLJS.U($CLJS.Pj,VN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:WO}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(bS,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",14,$CLJS.as,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.as,$CLJS.rG,$CLJS.U($CLJS.Pj,VN),fR,$CLJS.U($CLJS.Pj,VN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:VO}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&
"undefined"!==typeof qM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",13,$CLJS.Vr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.rG,$CLJS.U($CLJS.Pj,
VN),fR,$CLJS.U($CLJS.Pj,VN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:XO}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(UR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],
[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",14,$CLJS.Xr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.rG,$CLJS.U($CLJS.Pj,VN),fR,$CLJS.U($CLJS.Pj,VN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:dP}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&
"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(XP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",13,$CLJS.bs,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.bs,$CLJS.rG,$CLJS.U($CLJS.Pj,gS),fR,$CLJS.U($CLJS.Pj,gS),JQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,gS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:YO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(kN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,
$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GN,"metabase/mbql/schema.cljc",14,$CLJS.IG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IG,$CLJS.rG,$CLJS.U($CLJS.Pj,gS),fR,$CLJS.U($CLJS.Pj,gS),JQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,gS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(hU)?hU.H:null])):null));
return $CLJS.n(a)?a:GN}(),hU],null)])));
$CLJS.X(QP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"valid filter expression",$CLJS.Ni,function(a){return $CLJS.n(kM(eT,a))?$CLJS.jJ:$CLJS.n(kM(bT,a))?pS:$CLJS.n(kM(ZS,a))?$CLJS.Yj:$CLJS.n(kM(cT,a))?$CLJS.Ps:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,$CLJS.hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[pS,fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,mM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(uR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",
15,$CLJS.Gs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Gs,TN,$CLJS.U($CLJS.Pj,hP),LP,$CLJS.U($CLJS.Pj,hP),cO,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,hP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:cP}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(PP,new $CLJS.h(null,1,[$CLJS.ak,
!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",14,$CLJS.zs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zs,TN,$CLJS.U($CLJS.Pj,hP),LP,$CLJS.U($CLJS.Pj,hP),cO,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,hP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(bU)?
bU.H:null])):null));return $CLJS.n(a)?a:SP}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(CQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uP,"metabase/mbql/schema.cljc",15,$CLJS.Bs,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bs,$CLJS.iS,$CLJS.U($CLJS.Pj,hP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:uP}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(XP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",13,$CLJS.bs,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bs,$CLJS.rG,$CLJS.U($CLJS.Pj,gS),fR,$CLJS.U($CLJS.Pj,gS),JQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,gS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(gU)?gU.H:null])):
null));return $CLJS.n(a)?a:YO}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(kN,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GN,"metabase/mbql/schema.cljc",14,$CLJS.IG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IG,$CLJS.rG,$CLJS.U($CLJS.Pj,gS),fR,$CLJS.U($CLJS.Pj,gS),JQ,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,gS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:GN}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},
$CLJS.od(YP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",13,$CLJS.Zr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Zr,$CLJS.rG,$CLJS.U($CLJS.Pj,VN),fR,$CLJS.U($CLJS.Pj,VN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:WO}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(WP,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XO,"metabase/mbql/schema.cljc",13,$CLJS.Vr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.rG,$CLJS.U($CLJS.Pj,VN),fR,$CLJS.U($CLJS.Pj,VN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:XO}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(bS,new $CLJS.h(null,
1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",14,$CLJS.as,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.as,$CLJS.rG,$CLJS.U($CLJS.Pj,VN),fR,$CLJS.U($CLJS.Pj,VN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(kU)?kU.H:null])):
null));return $CLJS.n(a)?a:VO}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(UR,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dP,"metabase/mbql/schema.cljc",14,$CLJS.Xr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Xr,$CLJS.rG,$CLJS.U($CLJS.Pj,VN),fR,$CLJS.U($CLJS.Pj,VN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:dP}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(cja,new $CLJS.h(null,
1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mN,"metabase/mbql/schema.cljc",19,$CLJS.UF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UF,$CLJS.rG,$CLJS.U($CLJS.Pj,VN),$CLJS.Yn,$CLJS.U($CLJS.Pj,VN),$CLJS.ik,$CLJS.U($CLJS.Pj,VN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:mN}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kR,"metabase/mbql/schema.cljc",23,$CLJS.iG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iG,$CLJS.rG,$CLJS.U($CLJS.Pj,eR),FN,$CLJS.U($CLJS.Pj,eR),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,AQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:kR}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},
$CLJS.od(fia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KN,"metabase/mbql/schema.cljc",21,$CLJS.CG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CG,$CLJS.rG,$CLJS.U($CLJS.Pj,eR),FN,$CLJS.U($CLJS.Pj,eR),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,AQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:KN}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(sia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WN,"metabase/mbql/schema.cljc",20,$CLJS.qG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qG,$CLJS.rG,$CLJS.U($CLJS.Pj,eR),FN,$CLJS.U($CLJS.Pj,eR),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,AQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:WN}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==
typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AM,"metabase/mbql/schema.cljc",36,$CLJS.jG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.jG,$CLJS.rG,$CLJS.U($CLJS.Pj,eR),FN,$CLJS.U($CLJS.Pj,eR),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,AQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:AM}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(Gja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iR,"metabase/mbql/schema.cljc",26,$CLJS.$F,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.$F,Oha,$CLJS.U($CLJS.Pj,VN),bka,$CLJS.U($CLJS.Pj,VN),Nja,$CLJS.U($CLJS.Pj,VN),Rha,$CLJS.U($CLJS.Pj,VN),Hja,$CLJS.U($CLJS.Pj,VN),Nha,$CLJS.U($CLJS.Pj,
VN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:iR}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(tia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",28,$CLJS.VF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,$CLJS.rG,$CLJS.U($CLJS.Pj,ON)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:DP}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&
"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(rja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",29,$CLJS.bG,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,$CLJS.rG,$CLJS.U($CLJS.Pj,ON)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:rQ}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bO,"metabase/mbql/schema.cljc",27,$CLJS.TF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,$CLJS.rG,$CLJS.U($CLJS.Pj,ON)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:bO}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&
"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(Fia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rN,"metabase/mbql/schema.cljc",28,$CLJS.dG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,$CLJS.rG,$CLJS.U($CLJS.Pj,ON)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:rN}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(Fha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",33,$CLJS.DG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.DG,$CLJS.rG,$CLJS.U($CLJS.Pj,ON),$CLJS.qk,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.Ls,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,$CLJS.aw,$CLJS.cy,$CLJS.ZF],null)],null)),$CLJS.ri,$CLJS.U($CLJS.Pj,bP),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,hja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:QQ}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(Nia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SR,"metabase/mbql/schema.cljc",27,$CLJS.QF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,Vha,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,xja,dN],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:SR}(),yU],null)]))],null)],null));
$CLJS.X(DQ,lM($CLJS.aG,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.zt,":case subclause"],null),$CLJS.$T,lT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.zt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Us,!0],null),lT],null)],null)],null)])));
var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null);
$CLJS.X(jR,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od($P,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OO,"metabase/mbql/schema.cljc",51,$CLJS.ds,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ds,$CLJS.JE,$CLJS.U($CLJS.Pj,dQ),BR,$CLJS.U($CLJS.Pj,dQ),SN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,dQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:OO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},
$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",51,$CLJS.st,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.st,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q),
BR,$CLJS.U($CLJS.Pj,dQ),SN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,dQ))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:PO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM?new $CLJS.Gc(function(){return AT},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,rP,$CLJS.Si,$CLJS.mj,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.lA,"metabase/mbql/schema.cljc",$CLJS.MG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.MG,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q),BR,$CLJS.U($CLJS.Pj,$Q),SN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,$Q))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.lA}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NO,"metabase/mbql/schema.cljc",51,$CLJS.es,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.es,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q),BR,$CLJS.U($CLJS.Pj,$Q),SN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,$Q))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:NO}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},
$CLJS.od(NQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",58,$CLJS.uG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uG,KR,$CLJS.U($CLJS.Pj,FM),JR,$CLJS.U($CLJS.Pj,
FM),SN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,FM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:bR}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(qja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,
$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uQ,"metabase/mbql/schema.cljc",56,$CLJS.Xx,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xx,$CLJS.ty,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:uQ}(),pT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(gia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oP,"metabase/mbql/schema.cljc",55,$CLJS.pG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:oP}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(aia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cN,"metabase/mbql/schema.cljc",54,$CLJS.NF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(DT)?
DT.H:null])):null));return $CLJS.n(a)?a:cN}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",55,$CLJS.wG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:eP}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&
"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HP,"metabase/mbql/schema.cljc",53,$CLJS.SF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.SF,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:HP}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(Lja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,
nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[ZR,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",69,$CLJS.PF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.PF,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q),BR,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:tR}(),GT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(Iia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[ZR,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JO,"metabase/mbql/schema.cljc",68,$CLJS.eG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:JO}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Mja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),
$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[ZR,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",67,$CLJS.JF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(IT)?
IT.H:null])):null));return $CLJS.n(a)?a:gP}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(Uha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[ZR,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dS,"metabase/mbql/schema.cljc",67,$CLJS.OG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,$CLJS.JE,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:dS}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&
"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(QM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",61,$CLJS.aG,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.aG,$CLJS.jx,$CLJS.U($CLJS.Pj,hO),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,RP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:yP}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Xha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,
$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",65,$CLJS.fJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fJ,jia,$CLJS.U($CLJS.Pj,oS),kia,$CLJS.U($CLJS.Pj,oS),$CLJS.ri,$CLJS.U($CLJS.Pj,Qia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:IP}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(YQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aQ,"metabase/mbql/schema.cljc",71,$CLJS.uJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,oS),$CLJS.ri,$CLJS.U($CLJS.Pj,Rja),$CLJS.xi,$CLJS.U($CLJS.Pj,$CLJS.U(qR,wS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:aQ}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Bia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zN,"metabase/mbql/schema.cljc",71,$CLJS.bJ,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bJ,$CLJS.KP,$CLJS.U($CLJS.Pj,oS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:zN}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(qia,new $CLJS.h(null,1,[$CLJS.ak,!0],
null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",74,$CLJS.yJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yJ,$CLJS.KP,$CLJS.U($CLJS.Pj,oS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:ZP}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Xia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),
$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",72,$CLJS.VI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VI,$CLJS.KP,$CLJS.U($CLJS.Pj,oS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:wQ}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==
typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Qja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vQ,"metabase/mbql/schema.cljc",
71,$CLJS.oJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.oJ,$CLJS.KP,$CLJS.U($CLJS.Pj,oS),$CLJS.xi,$CLJS.U($CLJS.Pj,$CLJS.U(qR,wS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:vQ}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Lha,new $CLJS.h(null,1,[$CLJS.ak,
!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",70,$CLJS.nJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nJ,$CLJS.KP,$CLJS.U($CLJS.Pj,oS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:oQ}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),
$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",78,$CLJS.sJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,$CLJS.KP,$CLJS.U($CLJS.Pj,oS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:eQ}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==
typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GM,"metabase/mbql/schema.cljc",
71,$CLJS.vJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,oS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:GM}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(yja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,
$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hS,"metabase/mbql/schema.cljc",73,$CLJS.rJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,oS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(TT)?TT.H:null])):null));
return $CLJS.n(a)?a:hS}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(Mia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.uJ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,
[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",73,$CLJS.aJ,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,$CLJS.jJ,$CLJS.U($CLJS.Pj,oS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:AP}(),UT],null)])));
$CLJS.X(VM,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(oja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EQ,"metabase/mbql/schema.cljc",59,$CLJS.KF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KF,$CLJS.ty,$CLJS.U($CLJS.Pj,eR),$CLJS.Gv,$CLJS.U($CLJS.Pj,mia),$CLJS.Xx,$CLJS.U($CLJS.Pj,$CLJS.U(qR,$Q))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:EQ}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},
$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",54,$CLJS.HG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HG,$CLJS.ty,$CLJS.U($CLJS.Pj,eR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:aR}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(Sia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",55,$CLJS.JG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,$CLJS.ty,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:KQ}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&
"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",
55,$CLJS.QG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QG,$CLJS.ty,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:MP}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(Gha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,
$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MR,"metabase/mbql/schema.cljc",57,$CLJS.PG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PG,$CLJS.ty,$CLJS.U($CLJS.Pj,eR),Sha,$CLJS.U($CLJS.Pj,$CLJS.Yj),$CLJS.sA,$CLJS.U($CLJS.Pj,$CLJS.Yj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:MR}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rS,"metabase/mbql/schema.cljc",55,$CLJS.St,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.St,$CLJS.ty,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:rS}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==
typeof qM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",55,$CLJS.Mt,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mt,$CLJS.ty,$CLJS.U($CLJS.Pj,eR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:nR}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,
$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AO,"metabase/mbql/schema.cljc",56,$CLJS.RF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.RF,KR,$CLJS.U($CLJS.Pj,eR),JR,$CLJS.U($CLJS.Pj,eR),SN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,eR))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:AO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.hQ,"null",$CLJS.Mda,"null"],
null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xN,"metabase/mbql/schema.cljc",74,$CLJS.tG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tG,$CLJS.ty,$CLJS.U($CLJS.Pj,eR),$CLJS.rA,$CLJS.U($CLJS.Pj,$CLJS.Yj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:xN}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==
typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(NQ,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.hQ,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
bR,"metabase/mbql/schema.cljc",58,$CLJS.uG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uG,KR,$CLJS.U($CLJS.Pj,FM),JR,$CLJS.U($CLJS.Pj,FM),SN,$CLJS.U($CLJS.Pj,$CLJS.U($CLJS.Li,FM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:bR}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},
$CLJS.od(QM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",61,$CLJS.aG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aG,$CLJS.jx,$CLJS.U($CLJS.Pj,hO),
$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,RP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:yP}(),zU],null)])));
$CLJS.AU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,":field or :expression reference or expression",$CLJS.Ni,function(a){return $CLJS.n(kM(bT,a))?pS:$CLJS.n(kM(ZS,a))?$CLJS.Yj:$CLJS.n(kM(cT,a))?$CLJS.Ps:$CLJS.n(kM(eT,a))?$CLJS.jJ:$CLJS.n(kM($CLJS.aG,a))?$CLJS.aG:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[pS,fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,$CLJS.hT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,$CLJS.WS],null)],null);$CLJS.X(VR,lM($CLJS.Zx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,$CLJS.WS],null)])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VR],null);$CLJS.X(JP,lM($CLJS.sG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Us,$CLJS.WS],null)])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JP],null);$CLJS.X(TM,lM($CLJS.lG,$CLJS.H(["field-or-expression",$CLJS.AU])));
var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);$CLJS.X(NR,lM($CLJS.NG,$CLJS.H(["field-or-expression",$CLJS.AU])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(sO,lM($CLJS.XF,$CLJS.H(["field-or-expression",$CLJS.AU])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sO],null);$CLJS.X(cR,lM($CLJS.EG,$CLJS.H(["field-or-expression",$CLJS.AU])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cR],null);$CLJS.X(nO,lM($CLJS.Yn,$CLJS.H(["field-or-expression",$CLJS.AU])));
var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nO],null);$CLJS.X($N,lM($CLJS.ik,$CLJS.H(["field-or-expression",$CLJS.AU])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$N],null);$CLJS.X(UN,lM($CLJS.LG,$CLJS.H(["field-or-expression",$CLJS.AU,"pred",$CLJS.$T])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UN],null);$CLJS.X(kO,lM($CLJS.AG,$CLJS.H(["pred",$CLJS.$T])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kO],null);$CLJS.X(UQ,lM($CLJS.yG,$CLJS.H(["pred",$CLJS.$T])));
var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UQ],null);$CLJS.X(qN,lM($CLJS.GG,$CLJS.H(["field-or-expression",$CLJS.AU])));var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);$CLJS.X(sM,lM($CLJS.YF,$CLJS.H(["field-or-expression",$CLJS.AU])));var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);$CLJS.X(wO,lM($CLJS.FG,$CLJS.H(["field-or-expression",$CLJS.AU])));var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wO],null);$CLJS.X(jP,lM($CLJS.cG,$CLJS.H(["field-or-expression",$CLJS.AU,"percentile",jT])));
var PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jP],null);$CLJS.X(XQ,lM($CLJS.mG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null),xS],null)])));var QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XQ],null);
$CLJS.X(TQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"unnamed aggregation clause or numeric expression",$CLJS.Ni,function(a){return $CLJS.n(kM(bT,a))?mP:$CLJS.Ot}],null),new $CLJS.P(null,2,5,$CLJS.Q,[mP,fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ot,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(mja,
new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",60,$CLJS.lG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:vP}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(xia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AR,"metabase/mbql/schema.cljc",64,$CLJS.NG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:AR}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==
typeof qM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",65,$CLJS.XF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:zP}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.od(Ija,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,
$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BG,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NM,"metabase/mbql/schema.cljc",76,$CLJS.GG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:NM}(),MU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(bja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iN,"metabase/mbql/schema.cljc",60,$CLJS.EG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:iN}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,
$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ft,"metabase/mbql/schema.cljc",60,$CLJS.Yn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(HU)?HU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.ft}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Kha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.jt,"metabase/mbql/schema.cljc",60,$CLJS.ik,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:$CLJS.jt}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&
"undefined"!==typeof QU?new $CLJS.Gc(function(){return QU},$CLJS.od(lia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",18,$CLJS.mG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,yia,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.zs,Cia,dN],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:zO}(),QU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,
$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",62,$CLJS.yG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yG,$CLJS.qo,$CLJS.U($CLJS.Pj,hP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:rO}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Qha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eN,"metabase/mbql/schema.cljc",68,$CLJS.AG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,$CLJS.qo,$CLJS.U($CLJS.Pj,hP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:eN}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(Wja,new $CLJS.h(null,1,[$CLJS.ak,!0],
null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",66,$CLJS.LG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.LG,oR,$CLJS.U($CLJS.Pj,gR),$CLJS.qo,$CLJS.U($CLJS.Pj,hP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:wP}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(QM,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,
new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",61,$CLJS.aG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aG,$CLJS.jx,$CLJS.U($CLJS.Pj,hO),$CLJS.ej,$CLJS.U($CLJS.Pj,$CLJS.U(qR,RP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:yP}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&
"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.nG,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yR,"metabase/mbql/schema.cljc",
68,$CLJS.FG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:yR}(),OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof PU?new $CLJS.Gc(function(){return PU},$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,
rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.nG,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",72,$CLJS.cG,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cG,oR,$CLJS.U($CLJS.Pj,gR),$CLJS.cG,$CLJS.U($CLJS.Pj,$Q)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(PU)?PU.H:null])):
null));return $CLJS.n(a)?a:WQ}(),PU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.od($ha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IM,"metabase/mbql/schema.cljc",77,$CLJS.YF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,oR,$CLJS.U($CLJS.Pj,gR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:IM}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(Mha,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,
$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",74,$CLJS.sG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sG,$CLJS.rG,$CLJS.U($CLJS.Pj,$CLJS.U(qR,ON))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:iP}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(nia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([pP,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,sR,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.MF,"null"],null),null),
$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.wi,"metabase/mbql/schema.cljc",70,$CLJS.Zx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zx,$CLJS.rG,$CLJS.U($CLJS.Pj,$CLJS.U(qR,ON))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:$CLJS.wi}(),BU],null)]))],null)],null));
$CLJS.X(lQ,lM($CLJS.DM,$CLJS.H(["aggregation",TQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.zt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Us,!0],null),xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wE,new $CLJS.h(null,1,[$CLJS.Us,!0],null),xS],null)],null)])));
$CLJS.X(pR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,2,[$CLJS.zt,"aggregation clause or numeric expression",$CLJS.Ni,function(a){return $CLJS.n(kM($CLJS.DM,a))?$CLJS.DM:gO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[gO,TQ],null)],null));$CLJS.X(MM,lM($CLJS.oG,$CLJS.H(["field",YS])));var RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null);$CLJS.X(lP,lM($CLJS.hG,$CLJS.H(["field",YS])));
SU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lP],null);
nka=mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof RU?new $CLJS.Gc(function(){return RU},$CLJS.od(Fja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
SO,"metabase/mbql/schema.cljc",15,$CLJS.oG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,$CLJS.rG,$CLJS.U($CLJS.Pj,IR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:SO}(),RU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof SU?new $CLJS.Gc(function(){return SU},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",16,$CLJS.hG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.rG,$CLJS.U($CLJS.Pj,IR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(SU)?SU.H:null])):null));return $CLJS.n(a)?a:OQ}(),SU],null)]));
TU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ws,$CLJS.PM,$CLJS.EN,$CLJS.LF,$CLJS.au,$CLJS.DB,$CLJS.KP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wE,xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Us,!0],null),xS],null)],null);
oka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,TU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.PM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BM,xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bQ,AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),AS],null)],null)],null);
pka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,TU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.EN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lR,AS],null)],null)],null);
UU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,TU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Qs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Ps],null)],null)],null);qka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zR],null);
rka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,UU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.LF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.Wi,$CLJS.Qs],
null)],null)],null)],null)],null);$CLJS.VU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.KP,null,$CLJS.au,null,$CLJS.Ps,null,$CLJS.DB,null],null),null);ska=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ws],null),$CLJS.VU);
$CLJS.X(HQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.lj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,rka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PM,oka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EN,pka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,UU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,ska],null)],null)],null)],null)],null));
var WU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,xS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null)],null),tka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,WU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OF,$CLJS.Qs],null)],null)],null),XU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pQ],null),YU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.h(null,1,[$CLJS.Ni,function(a){return $CLJS.n($CLJS.pE($CLJS.xd,
$CLJS.sQ)(a))?$CLJS.sQ:$CLJS.vE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yt,WU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sQ,$CLJS.Qs],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vE,XU],null)],null),ZU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mD,yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[dja,xS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.MO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.hk],null)],null)],null),$U=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,fka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.zt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),uka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ws],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.qF,null,$CLJS.$E,null,$CLJS.jF,null,$CLJS.nF,null],null),null)),aV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xR],null);
$CLJS.X(qO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nP,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$U],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),YU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$R,$CLJS.$T],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,new $CLJS.h(null,1,[$CLJS.Us,!0],null),uka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.zt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.Ey,$CLJS.mA],null),aV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,new $CLJS.h(null,1,[$CLJS.Us,!0],null),xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iL,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$O,new $CLJS.h(null,
1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,ZU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.pE($CLJS.HA($CLJS.nP,$CLJS.RQ),$CLJS.Te($CLJS.pE($CLJS.nP,$CLJS.RQ)))],null)],null));var vka=$CLJS.Q,bV;var cV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qO],null)],null);
if($CLJS.wd(cV)&&$CLJS.E.g($CLJS.A(cV),$CLJS.yj)){var dV=$CLJS.y(cV);$CLJS.A(dV);var eV=$CLJS.B(dV),wka=$CLJS.xd($CLJS.A(eV))?eV:$CLJS.ee(null,eV),fV=$CLJS.y(wka),xka=$CLJS.A(fV),yka=$CLJS.B(fV);bV=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.R.j(xka,$CLJS.Yn,1)],null),yka)}else bV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,cV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(sN,new $CLJS.P(null,3,5,vka,[$CLJS.Gs,bV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"All join aliases must be unique."],null),function(a){return $CLJS.XL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.eF,a)))}],null)],null));var zka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);
$CLJS.X(xR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,1,[$CLJS.zt,"Distinct, non-empty sequence of Field clauses"],null),nM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),$CLJS.WS],null))],null));
$CLJS.X(pQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),YU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nP,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$U],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),$CLJS.iT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,new $CLJS.h(null,1,
[$CLJS.Us,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),$CLJS.WS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,xS,$CLJS.AU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,new $CLJS.h(null,1,[$CLJS.Us,!0],null),aV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.$T],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uS,new $CLJS.h(null,
1,[$CLJS.Us,!0],null),BS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YR,new $CLJS.h(null,1,[$CLJS.Us,!0],null),nM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),nka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[tQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[tQ,AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[eia,AS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MN,new $CLJS.h(null,1,[$CLJS.Us,!0],null),zka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$O,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,ZU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.dm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RQ,$CLJS.nP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,
new $CLJS.h(null,1,[$CLJS.zt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.fG);b=$CLJS.J.g(b,$CLJS.aF);return $CLJS.td($CLJS.ou.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.gV=$CLJS.Ig([$CLJS.KG,$CLJS.KP,mO,YN,aP,lS,HO,$CLJS.kS,$CLJS.au,GO,WM,dR,aS,$CLJS.lN,WR,jO,$CLJS.fP,EO,$CLJS.Yi,$CLJS.DO,EM,$CLJS.IN,$CLJS.CO,FP,$CLJS.Ps,$CLJS.DB,yO],[new $CLJS.h(null,1,[RO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,$CLJS.KP,null,$CLJS.au,null,$CLJS.Ps,null,$CLJS.DB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.KP,RO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,$CLJS.KP,null,$CLJS.kS,null,$CLJS.fP,null,$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.lj,$CLJS.Yj,$CLJS.EE,mR,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[mO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,pS,$CLJS.EE,mR,RO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,YN,null,$CLJS.au,null,$CLJS.Yi,null,EM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Yj,$CLJS.EE,$M,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[aP,null],null),null)],null),new $CLJS.h(null,1,[RO,new $CLJS.ah(null,new $CLJS.h(null,1,[lS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,
pS,$CLJS.EE,vja,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[HO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.KP,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,pS,RO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,YN,null,$CLJS.au,null,$CLJS.Yi,null,EM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,pS,$CLJS.EE,$M,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[GO,null],null),null)],null),new $CLJS.h(null,1,[RO,new $CLJS.ah(null,
new $CLJS.h(null,1,[WM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Yj,$CLJS.EE,mR,RO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.KG,null,lS,null,WM,null,dR,null,EO,null,$CLJS.Yi,null,EM,null,$CLJS.DB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Yj,$CLJS.EE,$M,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[aS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.KP,RO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.kS,null,$CLJS.lN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.lj,$CLJS.Yj,$CLJS.EE,$M,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[WR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,$CLJS.Yj,$CLJS.EE,$M,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[jO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.KP,RO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.KG,null,$CLJS.KP,null,$CLJS.kS,null,$CLJS.fP,null,$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,1,[RO,new $CLJS.ah(null,new $CLJS.h(null,1,[EO,null],null),null)],null),new $CLJS.h(null,1,[RO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.Yi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.KP,RO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.kS,null,$CLJS.DO,null],null),null)],null),new $CLJS.h(null,1,[RO,new $CLJS.ah(null,new $CLJS.h(null,1,[EM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.KP,RO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.kS,null,$CLJS.IN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.KP,RO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.kS,
null,$CLJS.CO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,pS,$CLJS.EE,mR,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[FP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.Ps,RO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.KG,null,$CLJS.Yi,null,$CLJS.Ps,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.lj,$CLJS.Yj,RO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.KG,null,lS,null,WM,null,dR,null,EO,null,$CLJS.Yi,null,EM,null,$CLJS.DB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.lj,
pS,$CLJS.EE,$M,RO,new $CLJS.ah(null,new $CLJS.h(null,1,[yO,null],null),null)],null)]);$CLJS.X(zR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.gV)));$CLJS.X(hN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.zt,"valid template tag widget type"],null),$CLJS.mA],null),$CLJS.Xg($CLJS.gV)));
$CLJS.X(MQ,lM(Wha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,xS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,xS],null)],null)],null)])));var hV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MQ],null);$CLJS.X(HM,lM($CLJS.LF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.WS,hV],null)])));var iV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null);$CLJS.X(CN,lM(BO,$CLJS.H(["target",hV])));
var jV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CN],null),Aka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.WS,mM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof iV?new $CLJS.Gc(function(){return iV},$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,
1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xQ,"metabase/mbql/schema.cljc",21,$CLJS.LF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.BJ,$CLJS.U($CLJS.Pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,ON,QO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(iV)?iV.H:null])):null));return $CLJS.n(a)?a:xQ}(),iV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=rP.h($CLJS.pd("undefined"!==typeof $CLJS.oM&&
"undefined"!==typeof pM&&"undefined"!==typeof qM&&"undefined"!==typeof jV?new $CLJS.Gc(function(){return jV},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.ak,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,rP,$CLJS.Si,$CLJS.mj,$CLJS.xk,nN,$CLJS.ti,$CLJS.jk,$CLJS.nk],[$CLJS.od(YQ,new $CLJS.h(null,1,[$CLJS.jk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qS,"metabase/mbql/schema.cljc",20,BO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[BO,
$CLJS.BJ,$CLJS.U($CLJS.Pj,QO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(jV)?jV.H:null])):null));return $CLJS.n(a)?a:qS}(),jV],null)]))],null),hM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);
$CLJS.X(oN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.pE($CLJS.HA($CLJS.sQ,$CLJS.OF),$CLJS.Te($CLJS.pE($CLJS.sQ,$CLJS.OF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.sQ);var d=$CLJS.J.g(c,$CLJS.OF);c=$CLJS.J.g(c,$CLJS.lj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.zt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.gL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.OF,$CLJS.sQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sQ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),tka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,new $CLJS.h(null,1,[$CLJS.Us,!0],null),XU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DN,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,qka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Us,!0],null),xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BJ,new $CLJS.h(null,1,[$CLJS.Us,!0],null),Aka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Qs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Us,!0],null),xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[bia,new $CLJS.h(null,1,[$CLJS.Us,!0],null),
xS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Qs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kL,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[Uja,new $CLJS.h(null,1,[$CLJS.Us,!0],null),KS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tia,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[xP,new $CLJS.h(null,1,[$CLJS.Us,!0],null),BS],null),new $CLJS.P(null,3,5,$CLJS.Q,[gN,new $CLJS.h(null,1,[$CLJS.Us,!0],null),BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,xP);b=$CLJS.J.g(b,gN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jia,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[Eja,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[Yha,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hha,new $CLJS.h(null,1,[$CLJS.Us,!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[dia,new $CLJS.h(null,1,[$CLJS.Us,
!0],null),$CLJS.Ps],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jha,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kja,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Ps],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oy,new $CLJS.h(null,
1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yN,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.ws,ija,fja,$CLJS.uO,hia,dka,kja,zia,Via,ria,Zha,via,tja,Hia,Tha,iia,cka,$ja],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[zja,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,AS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Eia,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lR,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,eka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vja,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,xS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Gia,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,AS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[Tja,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.Qs,$CLJS.Qs],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Aia,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xO,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Es,$CLJS.Qs,$CLJS.Qs],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UK,new $CLJS.h(null,1,[$CLJS.Us,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Qs],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.$O)],null)],null));var kV=$CLJS.gM(hM);
(function(){var a=Cha();return function(b){if($CLJS.n(kV.h?kV.h(b):kV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.dM(b);throw $CLJS.hi($CLJS.rE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Ky,c,Oia,b],null));}})();