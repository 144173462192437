var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var DH,EH,GH,HH,Gfa,Hfa,Ifa,IH,FH;$CLJS.BH=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.CH=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
DH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.zC(b,$CLJS.$C)?$CLJS.mH:$CLJS.zC(b,$CLJS.ZD)?$CLJS.jH:$CLJS.zC(b,$CLJS.zD)?$CLJS.nE:null;return $CLJS.n(b)?$CLJS.yF(b,a):!0};
EH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.BH(function(d){return $CLJS.zC($CLJS.WE(d),$CLJS.kD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Mk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.WE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(DH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
GH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,1,[$CLJS.zt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.Bt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Cj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(EH(b))].join("")}],null),$CLJS.Te(EH)],null)],null)};
HH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.zt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)],null)],null)};
Gfa=function(a){return $CLJS.Qd($CLJS.BF,$CLJS.hf.g(function(b){var c=$CLJS.WE(b),d=$CLJS.zC(c,$CLJS.hF);b=d?$CLJS.yF($CLJS.vG,b):d;return $CLJS.n(b)?$CLJS.Oj:c},a))};Hfa=function(a){a=$CLJS.CH(function(b){return!$CLJS.zC(b,$CLJS.kD)},$CLJS.hf.g($CLJS.WE,a));return $CLJS.zC(a,$CLJS.hF)?$CLJS.Vj:a};
Ifa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.zC($CLJS.WE(c),$CLJS.kD)},b))?Hfa(b):$CLJS.E.g(a,$CLJS.st)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.zC($CLJS.WE(c),$CLJS.$C)},b)||$CLJS.Qe(function(c){return $CLJS.zC($CLJS.WE(c),$CLJS.zD)},b))?$CLJS.kD:Gfa(b)};IH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.JH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);FH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,FH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.zt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.WE(a);return $CLJS.Qe(function(d){return DH(d,c)},b)}],null)],null));
$CLJS.X(IH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)],null));
var Jfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.zt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.st],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)],null)],
null);$CLJS.RG.g($CLJS.ds,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,GH($CLJS.ds),HH($CLJS.ds)],null));$CLJS.RG.g($CLJS.st,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,GH($CLJS.st),Jfa,HH($CLJS.st)],null));$CLJS.IF($CLJS.es,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.et,IH],null)]));$CLJS.IF($CLJS.MG,$CLJS.H([$CLJS.st,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.et,IH],null)]));
for(var KH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,$CLJS.st,$CLJS.es],null)),LH=null,MH=0,NH=0;;)if(NH<MH){var Kfa=LH.X(null,NH);$CLJS.zF(Kfa,$CLJS.JH);NH+=1}else{var OH=$CLJS.y(KH);if(OH){var PH=OH;if($CLJS.Ad(PH)){var QH=$CLJS.lc(PH),Lfa=$CLJS.mc(PH),Mfa=QH,Nfa=$CLJS.D(QH);KH=Lfa;LH=Mfa;MH=Nfa}else{var Ofa=$CLJS.A(PH);$CLJS.zF(Ofa,$CLJS.JH);KH=$CLJS.B(PH);LH=null;MH=0}NH=0}else break}
$CLJS.VE.m(null,$CLJS.JH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Ifa(a,b)});$CLJS.GF($CLJS.SF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.zF($CLJS.SF,$CLJS.lF);
for(var RH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,$CLJS.JF,$CLJS.eG],null)),SH=null,TH=0,UH=0;;)if(UH<TH){var Pfa=SH.X(null,UH);$CLJS.GF(Pfa,$CLJS.H([$CLJS.st,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));UH+=1}else{var VH=$CLJS.y(RH);if(VH){var WH=VH;if($CLJS.Ad(WH)){var XH=$CLJS.lc(WH),Qfa=$CLJS.mc(WH),Rfa=XH,Sfa=$CLJS.D(XH);RH=Qfa;SH=Rfa;TH=Sfa}else{var Tfa=$CLJS.A(WH);$CLJS.GF(Tfa,$CLJS.H([$CLJS.st,$CLJS.WD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));RH=$CLJS.B(WH);SH=null;TH=0}UH=0}else break}
for(var YH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,$CLJS.pG,$CLJS.wG],null)),ZH=null,$H=0,aI=0;;)if(aI<$H){var Ufa=ZH.X(null,aI);$CLJS.GF(Ufa,$CLJS.H([$CLJS.st,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));aI+=1}else{var bI=$CLJS.y(YH);if(bI){var cI=bI;if($CLJS.Ad(cI)){var dI=$CLJS.lc(cI),Vfa=$CLJS.mc(cI),Wfa=dI,Xfa=$CLJS.D(dI);YH=Vfa;ZH=Wfa;$H=Xfa}else{var Yfa=$CLJS.A(cI);$CLJS.GF(Yfa,$CLJS.H([$CLJS.st,$CLJS.vj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));YH=$CLJS.B(cI);ZH=null;$H=0}aI=0}else break}$CLJS.GF($CLJS.PF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));
$CLJS.VE.m(null,$CLJS.PF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.zC($CLJS.WE(b),$CLJS.vj)&&$CLJS.zC($CLJS.WE(a),$CLJS.vj)?$CLJS.vj:$CLJS.WD});